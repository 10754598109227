//Botones
.marketplace-default {
  .mat-mdc-button,
  .mat-mdc-fab,
  .mat-mdc-raised-button,
  .mat-mdc-mini-fab,
  .mat-mdc-icon-button,
  .mat-mdc-unelevated-button,
  .mat-mdc-raised-button,
  .mat-mdc-outlined-button {
    & > .mat-icon {
      color: inherit;
    }

    &.mat-accent:not(.mat-button-disabled) {
      background-color: $color-coral;
      color: $color-white;
    }

    &.mat-accent-inverted:not(.mat-button-disabled) {
      background-color: $color-white;
      color: $color-coral;
    }

    &.mat-dark-blue:not(.mat-button-disabled),
    &.mat-primary:not(.mat-button-disabled) {
      background-color: $color-dark-blue-1;
      color: $color-white;
    }

    &.mat-dark-blue-inverted:not(.mat-button-disabled) {
      background-color: $color-white;
      color: $color-dark-blue-1;
    }

    &.mat-light-blue:not(.mat-button-disabled) {
      background-color: $color-light-blue;
      color: $color-dark-blue-1;
    }

    &.mat-light-grey-2:not(.mat-button-disabled) {
      background-color: $color-light-grey-2;
      color: $color-dark-grey-1;
    }

    &.mat-orange:not(.mat-button-disabled) {
      background-color: $color-orange;
      color: $color-white;
    }

    &.mat-white:not(.mat-button-disabled) {
      background-color: $color-white;
      color: $color-dark-blue;
    }

    &.mat-transparent:not(.mat-button-disabled):not(.mat-mdc-outlined-button) {
      background-color: $color-transparent;
      color: $color-dark-grey;
    }

    &.mat-light-purple {
      background-color: $color-light-purple !important;
      color: $color-dark-grey-1 !important;
    }

    &.mat-purple {
      background-color: $color-purple !important;
      color: $color-white !important;
    }

    &.mat-purple-inverted {
      background-color: $color-transparent !important;
      color: $color-purple !important;
    }
  }

  .mat-mdc-outlined-button {
    &.mat-transparent {
      background-color: $color-transparent;
      border: 1px solid $color-light-purple-1;
      color: $color-dark-blue-1;
    }
  }

  .mat-mdc-raised-button {
    @include elevation-option-active;
  }

  .mat-mdc-fab:not(.mdc-fab--extended) {
    align-items: center;
    border-radius: 6px !important;
    box-shadow: none;
    display: inline-flex;
    flex-flow: row nowrap;
    height: 40px;
    justify-content: center;
    width: 40px;

    & .mat-icon {
      color: inherit;
      overflow: visible;
    }

    & .mat-mdc-button-persistent-ripple {
      border-radius: 6px !important;
    }
  }

  .mat-mdc-mini-fab:not(.mdc-fab--extended) {
    align-items: center;
    border-radius: 50%;
    display: inline-flex;
    flex-flow: row nowrap;
    height: 40px;
    justify-content: center;
    margin: 0;
    width: 40px;
    @include elevation-option-active;
    @include media-breakpoint('mobile') {
      height: 56px;
      line-height: 56px;
      width: 56px;

      & .mat-mdc-button-persistent-ripple {
        border-radius: 50%;
      }
    }
  }

  .mat-mdc-icon-button {
    align-items: center;
    color: $color-black;
    display: inline-flex;
    flex-flow: row nowrap;
    height: 40px;
    justify-content: center;
    padding: 5px;
    width: 40px;

    & .mat-mdc-button-persistent-ripple:before {
      background-color: transparent;
    }
  }

  .mat-mdc-button[disabled],
  .mat-mdc-fab[disabled],
  .mat-mdc-mini-fab[disabled],
  .mat-mdc-unelevated-button[disabled],
  .mat-mdc-raised-button[disabled],
  .mat-mdc-outlined-button[disabled] {
    background-color: $color-light-grey-0 !important;
    color: $color-white !important;
    cursor: default;
    pointer-events: none;
  }

  .mat-mdc-icon-button[disabled] {
    background-color: $color-transparent !important;
    color: $color-light-grey-0 !important;
    cursor: default;
    pointer-events: none;
  }

  .mat-mdc-button .mat-mdc-button-touch-target,
  .mat-mdc-unelevated-button .mat-mdc-button-touch-target,
  .mat-mdc-raised-button .mat-mdc-button-touch-target,
  .mat-mdc-outlined-button .mat-mdc-button-touch-target {
    height: inherit !important;
  }
}

/*********************************************** Nuevos estilos ***********************************************/
.marketplace-default {
  //botones normales estilos genéricos
  .mat-mdc-button,
  .mat-mdc-raised-button,
  .mat-mdc-outlined-button,
  .mat-mdc-unelevated-button {
    & .mat-mdc-button-persistent-ripple {
      background-color: transparent;
      border-radius: inherit;
      height: inherit !important;
      min-height: inherit !important;
      min-width: inherit !important;
      overflow: hidden;
      width: inherit !important;

      &::before {
        border-radius: inherit;
        content: '';
        height: inherit !important;
        min-height: inherit !important;
        min-width: inherit !important;
        opacity: 0;
        overflow: hidden;
        width: inherit !important;
      }
    }

    & .mat-mdc-focus-indicator,
    & .mat-mdc-button-ripple {
      border-radius: inherit;
      height: inherit !important;
      min-height: inherit !important;
      min-width: inherit !important;
      overflow: hidden;
      width: inherit !important;
    }

    & .mat-mdc-button-touch-target {
      border-radius: inherit;
      height: inherit !important;
      min-height: inherit !important;
      min-width: inherit !important;
      overflow: hidden;
      width: inherit !important;
    }
  }

  //botón tipo enlace (basics)
  .mat-mdc-button {
    &.small {
      border-radius: 6px;
      font-size: $font-size-16;
      font-weight: 500;
      gap: 6px;
      height: auto;
      line-height: $font-size-18;
      min-height: initial;
      min-width: initial;
      padding: $padding-7-12;
      width: auto;

      & > .mat-icon {
        font-size: $font-size-17;
        height: $font-size-17;
        line-height: $font-size-17;
        margin: 0;
        width: $font-size-17;
      }
    }

    &.big {
      border-radius: 6px;
      font-size: $font-size-16;
      font-weight: 500;
      gap: 8px;
      height: auto;
      line-height: $font-size-20;
      min-height: initial;
      min-width: initial;
      padding: $padding-12-16;
      width: auto;

      & > .mat-icon {
        font-size: $font-size-19;
        height: $font-size-19;
        line-height: $font-size-19;
        margin: 0;
        width: $font-size-19;
      }
    }

    &.mat-unthemed:not([disabled]) {
      background-color: $color-transparent;
      color: $color-black-111111;

      &:hover {
        background-color: $color-black-4;
      }

      &:active {
        background-color: $color-black-12;
      }
    }

    &.mat-primary:not([disabled]) {
      background-color: $color-transparent;
      color: $color-blue-dark;

      &:hover {
        background-color: $color-blue-4;
      }

      &:active {
        background-color: $color-blue-12;
      }
    }

    &.mat-warn:not([disabled]) {
      background-color: $color-transparent;
      color: $color-red-medium;

      &:hover {
        background-color: $color-red-4;
      }

      &:active {
        background-color: $color-red-12;
      }
    }

    &.mat-accent:not([disabled]) {
      background-color: $color-transparent;
      color: $color-violet-medium;

      &:hover {
        background-color: $color-violet-4;
      }

      &:active {
        background-color: $color-violet-12;
      }
    }

    &.big,
    &.small {
      &.mat-mdc-button-disabled,
      &[disabled] {
        background-color: $color-transparent !important;
        color: $color-grey-light !important;
      }
    }
  }

  //botón con sombra (raised)
  .mat-mdc-raised-button {
    &.small {
      border-radius: 6px;
      font-size: $font-size-14;
      font-weight: 600;
      gap: 6px;
      height: auto;
      line-height: $font-size-18;
      min-height: initial;
      min-width: initial;
      padding: $padding-7-12;
      width: auto;

      & > .mat-icon {
        font-size: $font-size-17;
        height: $font-size-17;
        line-height: $font-size-17;
        margin: 0;
        width: $font-size-17;
      }
    }

    &.big {
      border-radius: 6px;
      font-size: $font-size-16;
      font-weight: 600;
      gap: 8px;
      height: auto;
      line-height: $font-size-20;
      min-height: initial;
      min-width: initial;
      padding: $padding-12-16;
      width: auto;

      & > .mat-icon {
        font-size: $font-size-19;
        height: $font-size-19;
        line-height: $font-size-19;
        margin: 0;
        width: $font-size-19;
      }
    }

    //inventado
    &.mat-unthemed:not([disabled]) {
      background-color: $color-transparent;
      box-shadow:
        0 3px 6.2px -2px rgba(0, 0, 0, 0.2),
        0 2px 2.2px 0 rgba(0, 0, 0, 0.14),
        0 1px 4.6px 0 rgba(0, 0, 0, 0.12) !important;
      color: $color-black-111111;

      &:hover {
        background-color: $color-black-4;
        box-shadow:
          0 8px 12.1px -2px rgba(0, 0, 0, 0.16),
          0 4px 4.2px 0 rgba(0, 0, 0, 0.14),
          0 2px 15.3px 0 rgba(0, 0, 0, 0.18) !important;
      }

      &:active {
        background-color: $color-black-12;
        box-shadow:
          0 4px 6.9px -2px rgba(0, 0, 0, 0.18),
          0 4px 8.6px 0 rgba(0, 0, 0, 0.16),
          0 2px 15.3px 0 rgba(0, 0, 0, 0.13) !important;
      }
    }

    &.mat-primary:not([disabled]) {
      background-color: $color-blue-dark;
      box-shadow:
        0 3px 6.2px -2px rgba(8, 31, 54, 0.2),
        0 2px 2.2px 0 rgba(8, 31, 54, 0.14),
        0 1px 4.6px 0 rgba(8, 31, 54, 0.12) !important;
      color: $color-white;

      &:hover {
        background-color: $color-blue-87;
        box-shadow:
          0 8px 12.1px -2px rgba(8, 31, 54, 0.16),
          0 4px 4.2px 0 rgba(8, 31, 54, 0.14),
          0 2px 15.3px 0 rgba(8, 31, 54, 0.18) !important;
      }

      &:active {
        background-color: $color-blue-medium;
        box-shadow:
          0 4px 6.9px -2px rgba(8, 31, 54, 0.18),
          0 4px 8.6px 0 rgba(8, 31, 54, 0.16),
          0 2px 15.3px 0 rgba(8, 31, 54, 0.13) !important;
      }
    }

    &.mat-warn:not([disabled]) {
      background-color: $color-red-medium;
      box-shadow:
        0 3px 6.2px -2px rgba(244, 67, 54, 0.2),
        0 2px 2.2px 0 rgba(244, 67, 54, 0.14),
        0 1px 4.6px 0 rgba(244, 67, 54, 0.12) !important;
      color: $color-white;

      &:hover {
        background-color: $color-red-strong;
        box-shadow:
          0 8px 12.1px -2px rgba(244, 67, 54, 0.16),
          0 4px 4.2px 0 rgba(244, 67, 54, 0.14),
          0 2px 15.3px 0 rgba(244, 67, 54, 0.18) !important;
      }

      &:active {
        background-color: $color-red-medium;
        box-shadow:
          0 4px 6.9px -2px rgba(244, 67, 54, 0.18),
          0 4px 8.6px 0 rgba(244, 67, 54, 0.16),
          0 2px 15.3px 0 rgba(244, 67, 54, 0.13) !important;
      }
    }

    &.mat-accent:not([disabled]) {
      background-color: $color-violet-dark;
      box-shadow:
        0 3px 6.2px -2px rgba(180, 40, 190, 0.2),
        0 2px 2.2px 0 rgba(180, 40, 190, 0.14),
        0 1px 4.6px 0 rgba(180, 40, 190, 0.12) !important;
      color: $color-white;

      &:hover {
        background-color: $color-violet-hover;
        box-shadow:
          0 8px 12.1px -2px rgba(180, 40, 190, 0.16),
          0 4px 4.2px 0 rgba(180, 40, 190, 0.14),
          0 2px 15.3px 0 rgba(180, 40, 190, 0.18) !important;
      }

      &:active {
        background-color: $color-violet-medium;
        box-shadow:
          0 3px 6.2px -2px rgba(180, 40, 190, 0.2),
          0 2px 2.2px 0 rgba(180, 40, 190, 0.14),
          0 1px 4.6px 0 rgba(180, 40, 190, 0.12) !important;
      }
    }

    &.big,
    &.small {
      &.mat-mdc-button-disabled,
      &[disabled] {
        background-color: $color-grey-lightest !important;
        color: $color-grey-light !important;
      }
    }
  }

  //botón con borde sin sombra (stroked)
  .mat-mdc-outlined-button {
    &.small {
      border-radius: 6px;
      font-size: $font-size-14;
      font-weight: 500;
      gap: 6px;
      height: auto;
      line-height: $font-size-18;
      min-height: initial;
      min-width: initial;
      padding: $padding-6-12;
      width: auto;

      & > .mat-icon {
        font-size: $font-size-17;
        height: $font-size-17;
        line-height: $font-size-17;
        margin: 0;
        width: $font-size-17;
      }
    }

    &.big {
      border-radius: 6px;
      font-size: $font-size-16;
      font-weight: 500;
      gap: 8px;
      height: auto;
      line-height: $font-size-20;
      min-height: initial;
      min-width: initial;
      padding: $padding-11-16;
      width: auto;

      & > .mat-icon {
        font-size: $font-size-19;
        height: $font-size-19;
        line-height: $font-size-19;
        margin: 0;
        width: $font-size-19;
      }
    }

    &.mat-unthemed:not([disabled]) {
      background-color: $color-transparent;
      border: 1px solid $color-black-48;
      color: $color-black-111111;

      &:hover {
        background-color: $color-black-4;
      }

      &:active {
        background-color: $color-black-12;
      }
    }

    &.mat-primary:not([disabled]) {
      background-color: $color-transparent;
      border: 1px solid $color-blue-32;
      color: $color-blue-dark;

      &:hover {
        background-color: $color-blue-4;
      }

      &:active {
        background-color: $color-blue-12;
      }
    }

    &.mat-warn:not([disabled]) {
      background-color: $color-transparent;
      border: 1px solid $color-red-light;
      color: $color-red-medium;

      &:hover {
        background-color: $color-red-4;
      }

      &:active {
        background-color: $color-red-12;
      }
    }

    &.mat-accent:not([disabled]) {
      background-color: $color-transparent;
      border: 1px solid $color-violet-32;
      color: $color-violet-medium;

      &:hover {
        background-color: $color-violet-4;
      }

      &:active {
        background-color: $color-violet-12;
      }
    }

    &.big,
    &.small {
      &.mat-mdc-button-disabled,
      &[disabled] {
        background-color: $color-transparent !important;
        border: 1px solid $color-grey-lighter;
        color: $color-grey-light !important;
      }
    }
  }

  //botón con fondo sin sombra (flat)
  .mat-mdc-unelevated-button {
    &.small {
      border-radius: 6px;
      font-size: $font-size-14;
      font-weight: 500;
      gap: 6px;
      height: auto;
      line-height: $font-size-18;
      min-height: initial;
      min-width: initial;
      padding: $padding-7-12;
      width: auto;

      & > .mat-icon {
        font-size: $font-size-17;
        height: $font-size-17;
        line-height: $font-size-17;
        margin: 0;
        width: $font-size-17;
      }
    }

    &.big {
      border-radius: 6px;
      font-size: $font-size-16;
      font-weight: 500;
      gap: 8px;
      height: auto;
      line-height: $font-size-20;
      min-height: initial;
      min-width: initial;
      padding: $padding-12-16;
      width: auto;

      & > .mat-icon {
        font-size: $font-size-19;
        height: $font-size-19;
        line-height: $font-size-19;
        margin: 0;
        width: $font-size-19;
      }
    }

    &.mat-unthemed:not([disabled]) {
      background-color: $color-transparent;
      color: $color-black-111111;

      &:hover {
        background-color: $color-black-4;
      }

      &:active {
        background-color: $color-black-12;
      }
    }

    &.mat-primary:not([disabled]) {
      background-color: $color-blue-dark;
      color: $color-white;

      &:hover {
        background-color: $color-blue-87;
      }

      &:active {
        background-color: $color-blue-medium;
      }
    }

    &.mat-warn:not([disabled]) {
      background-color: $color-red-medium;
      color: $color-white;

      &:hover {
        background-color: $color-red-strong;
      }

      &:active {
        background-color: $color-red-medium;
      }
    }

    &.mat-accent:not([disabled]) {
      background-color: $color-violet-dark;
      color: $color-white;

      &:hover {
        background-color: $color-violet-hover;
      }

      &:active {
        background-color: $color-violet-medium;
      }
    }

    &.big,
    &.small {
      &.mat-mdc-button-disabled,
      &[disabled] {
        background-color: $color-grey-lightest !important;
        color: $color-grey-light !important;
      }
    }
  }

  //botones de iconos redondos sin sombra (icons)
  .mat-mdc-icon-button {
    &.small {
      font-size: $font-size-20;
      height: auto;
      line-height: $font-size-20;
      min-height: initial;
      min-width: initial;
      padding: $padding-6;
      width: auto;

      & > .mat-mdc-button-touch-target {
        height: 32px;
      }

      & > .mat-icon {
        font-size: $font-size-20;
        height: $font-size-20;
        line-height: $font-size-20;
        margin: 0;
        width: $font-size-20;
      }
    }

    &.big {
      font-size: $font-size-24;
      height: auto;
      line-height: $font-size-24;
      min-height: initial;
      min-width: initial;
      padding: $padding-10;
      width: auto;

      & > .mat-mdc-button-touch-target {
        height: 44px;
      }

      & > .mat-icon {
        font-size: $font-size-24;
        height: $font-size-24;
        line-height: $font-size-24;
        margin: 0;
        width: $font-size-24;
      }
    }

    &.mat-unthemed:not([disabled]) {
      background-color: $color-transparent;
      color: $color-black-111111;

      &:hover {
        background-color: $color-black-4;
      }

      &:active {
        background-color: $color-black-12;
      }
    }

    &.mat-primary:not([disabled]) {
      background-color: $color-transparent;
      color: $color-blue-dark;

      &:hover {
        background-color: $color-blue-4;
      }

      &:active {
        background-color: $color-blue-12;
      }
    }

    &.mat-warn:not([disabled]) {
      background-color: $color-transparent;
      color: $color-red-medium;

      &:hover {
        background-color: $color-red-4;
      }

      &:active {
        background-color: $color-red-12;
      }
    }

    &.mat-accent:not([disabled]) {
      background-color: $color-transparent;
      color: $color-violet-medium;

      &:hover {
        background-color: $color-violet-hover;
      }

      &:active {
        background-color: $color-violet-12;
      }
    }

    &.big,
    &.small {
      &.mat-mdc-button-disabled,
      &[disabled] {
        background-color: $color-transparent !important;
        color: $color-grey-light !important;
      }
    }
  }

  //botones de iconos redondos con sombra (mini fab)
  .mat-mdc-mini-fab {
    &.small {
      font-size: $font-size-20;
      height: auto;
      line-height: $font-size-20;
      min-height: initial;
      min-width: initial;
      padding: $padding-6;
      width: auto;

      & > .mat-icon {
        font-size: $font-size-20;
        height: $font-size-20;
        line-height: $font-size-20;
        margin: 0;
        width: $font-size-20;
      }
    }

    &.big {
      font-size: $font-size-24;
      height: auto;
      line-height: $font-size-24;
      min-height: initial;
      min-width: initial;
      padding: $padding-10;
      width: auto;

      & > .mat-icon {
        font-size: $font-size-24;
        height: $font-size-24;
        line-height: $font-size-24;
        margin: 0;
        width: $font-size-24;
      }
    }

    &.mat-primary:not([disabled]) {
      background-color: $color-blue-dark;
      box-shadow:
        0 3px 6.2px -2px rgba(8, 31, 54, 0.2),
        0 2px 2.2px 0 rgba(8, 31, 54, 0.14),
        0 1px 4.6px 0 rgba(8, 31, 54, 0.12) !important;
      color: $color-white;

      &:hover {
        background-color: $color-blue-87;
        box-shadow:
          0 8px 12.1px -2px rgba(8, 31, 54, 0.16),
          0 4px 4.2px 0 rgba(8, 31, 54, 0.14),
          0 2px 15.3px 0 rgba(8, 31, 54, 0.18) !important;
      }

      &:active {
        background-color: $color-blue-medium;
        box-shadow:
          0 4px 6.9px -2px rgba(8, 31, 54, 0.18),
          0 4px 8.6px 0 rgba(8, 31, 54, 0.16),
          0 2px 15.3px 0 rgba(8, 31, 54, 0.13) !important;
      }
    }

    &.mat-warn:not([disabled]) {
      background-color: $color-red-medium;
      box-shadow:
        0 3px 6.2px -2px rgba(244, 67, 54, 0.2),
        0 2px 2.2px 0 rgba(244, 67, 54, 0.14),
        0 1px 4.6px 0 rgba(244, 67, 54, 0.12) !important;
      color: $color-white;

      &:hover {
        background-color: $color-red-strong;
        box-shadow:
          0 8px 12.1px -2px rgba(244, 67, 54, 0.16),
          0 4px 4.2px 0 rgba(244, 67, 54, 0.14),
          0 2px 15.3px 0 rgba(244, 67, 54, 0.18) !important;
      }

      &:active {
        background-color: $color-red-medium;
        box-shadow:
          0 4px 6.9px -2px rgba(244, 67, 54, 0.18),
          0 4px 8.6px 0 rgba(244, 67, 54, 0.16),
          0 2px 15.3px 0 rgba(244, 67, 54, 0.13) !important;
      }
    }

    &.mat-accent:not([disabled]) {
      background-color: $color-violet-dark;
      box-shadow:
        0 3px 6.2px -2px rgba(180, 40, 190, 0.2),
        0 2px 2.2px 0 rgba(180, 40, 190, 0.14),
        0 1px 4.6px 0 rgba(180, 40, 190, 0.12) !important;
      color: $color-white;

      &:hover {
        background-color: $color-violet-hover;
        box-shadow:
          0 8px 12.1px -2px rgba(180, 40, 190, 0.16),
          0 4px 4.2px 0 rgba(180, 40, 190, 0.14),
          0 2px 15.3px 0 rgba(180, 40, 190, 0.18) !important;
      }

      &:active {
        background-color: $color-violet-medium;
        box-shadow:
          0 3px 6.2px -2px rgba(180, 40, 190, 0.2),
          0 2px 2.2px 0 rgba(180, 40, 190, 0.14),
          0 1px 4.6px 0 rgba(180, 40, 190, 0.12) !important;
      }
    }

    &.big,
    &.small {
      &.mat-mdc-button-disabled,
      &[disabled] {
        background-color: $color-grey-lightest !important;
        box-shadow: none !important;
        color: $color-grey-light !important;
      }
    }
  }

  //botones de iconos cuadrados con sombra (fab)
  .mat-mdc-fab:not(.mdc-fab--extended) {
    &.small {
      font-size: $font-size-20;
      height: auto;
      line-height: $font-size-20;
      min-height: initial;
      min-width: initial;
      padding: $padding-6;
      width: auto;

      & > .mat-icon {
        font-size: $font-size-20;
        height: $font-size-20;
        line-height: $font-size-20;
        margin: 0;
        width: $font-size-20;
      }
    }

    &.big {
      font-size: $font-size-24;
      height: auto;
      line-height: $font-size-24;
      min-height: initial;
      min-width: initial;
      padding: $padding-10;
      width: auto;

      & > .mat-icon {
        font-size: $font-size-24;
        height: $font-size-24;
        line-height: $font-size-24;
        margin: 0;
        width: $font-size-24;
      }
    }

    &.mat-primary:not([disabled]) {
      background-color: $color-blue-dark;
      box-shadow:
        0 3px 6.2px -2px rgba(8, 31, 54, 0.2),
        0 2px 2.2px 0 rgba(8, 31, 54, 0.14),
        0 1px 4.6px 0 rgba(8, 31, 54, 0.12) !important;
      color: $color-white;

      &:hover {
        background-color: $color-blue-87;
        box-shadow:
          0 8px 12.1px -2px rgba(8, 31, 54, 0.16),
          0 4px 4.2px 0 rgba(8, 31, 54, 0.14),
          0 2px 15.3px 0 rgba(8, 31, 54, 0.18) !important;
      }

      &:active {
        background-color: $color-blue-medium;
        box-shadow:
          0 4px 6.9px -2px rgba(8, 31, 54, 0.18),
          0 4px 8.6px 0 rgba(8, 31, 54, 0.16),
          0 2px 15.3px 0 rgba(8, 31, 54, 0.13) !important;
      }
    }

    &.mat-warn:not([disabled]) {
      background-color: $color-red-medium;
      box-shadow:
        0 3px 6.2px -2px rgba(244, 67, 54, 0.2),
        0 2px 2.2px 0 rgba(244, 67, 54, 0.14),
        0 1px 4.6px 0 rgba(244, 67, 54, 0.12) !important;
      color: $color-white;

      &:hover {
        background-color: $color-red-strong;
        box-shadow:
          0 8px 12.1px -2px rgba(244, 67, 54, 0.16),
          0 4px 4.2px 0 rgba(244, 67, 54, 0.14),
          0 2px 15.3px 0 rgba(244, 67, 54, 0.18) !important;
      }

      &:active {
        background-color: $color-red-medium;
        box-shadow:
          0 4px 6.9px -2px rgba(244, 67, 54, 0.18),
          0 4px 8.6px 0 rgba(244, 67, 54, 0.16),
          0 2px 15.3px 0 rgba(244, 67, 54, 0.13) !important;
      }
    }

    &.mat-accent:not([disabled]) {
      background-color: $color-violet-dark;
      box-shadow:
        0 3px 6.2px -2px rgba(180, 40, 190, 0.2),
        0 2px 2.2px 0 rgba(180, 40, 190, 0.14),
        0 1px 4.6px 0 rgba(180, 40, 190, 0.12) !important;
      color: $color-white;

      &:hover {
        background-color: $color-violet-hover;
        box-shadow:
          0 8px 12.1px -2px rgba(180, 40, 190, 0.16),
          0 4px 4.2px 0 rgba(180, 40, 190, 0.14),
          0 2px 15.3px 0 rgba(180, 40, 190, 0.18) !important;
      }

      &:active {
        background-color: $color-violet-medium;
        box-shadow:
          0 3px 6.2px -2px rgba(180, 40, 190, 0.2),
          0 2px 2.2px 0 rgba(180, 40, 190, 0.14),
          0 1px 4.6px 0 rgba(180, 40, 190, 0.12) !important;
      }
    }

    &.big,
    &.small {
      &.mat-mdc-button-disabled,
      &[disabled] {
        background-color: $color-grey-lightest !important;
        box-shadow: none !important;
        color: $color-grey-light !important;
      }
    }
  }
}