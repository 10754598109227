//market

$color-transparent: transparent;
$color-white: #ffffff;
$color-white-08: rgb(255, 255, 255, 8%);
$color-white-21: rgb(255, 255, 255, 21%);
$color-white-80: rgb(255, 255, 255, 80%);

$color-black-01: rgba(0, 0, 0, 0.01);
$color-black-02: rgba(0, 0, 0, 0.02);
$color-black-03: rgba(0, 0, 0, 0.03);
$color-black-04: rgba(0, 0, 0, 0.04);
$color-black-10: rgba(0, 0, 0, 0.1);
$color-black-12: rgba(0, 0, 0, 0.12);
$color-black-14: rgba(0, 0, 0, 0.14);
$color-black-20: rgba(0, 0, 0, 0.2);
$color-black-30: rgba(0, 0, 0, 0.3);
$color-black-25: rgba(0, 0, 0, 0.25);
$color-black-40: rgba(0, 0, 0, 0.4);
$color-black-42: rgba(0, 0, 0, 0.42);
$color-black-50: rgba(0, 0, 0, 0.5);
$color-black-54: rgba(0, 0, 0, 0.54);
$color-black-60: rgba(0, 0, 0, 0.6);
$color-black-75: rgba(0, 0, 0, 0.75);
$color-black-80: rgba(0, 0, 0, 0.8);
$color-black-87: rgba(0, 0, 0, 0.87);
$color-black: #000000;

$palette-panel-0: #fffaf8;
$palette-panel-05: #ffeee6;
$palette-panel-1: #fee3d5;
$palette-panel-15: #fed7c4;
$palette-panel-2: #fdccb2;
$palette-panel-3: #fdb48f;
$palette-panel-4: #fc9d6c;
$palette-panel-5: #fb8649;
$palette-panel-6: #e5753b;
$palette-panel-7: #cf652d;
$palette-panel-8: #ba541f;
$palette-panel-85: #af4c18;
$palette-panel-9: #a44411;
$palette-panel-95: #993b0a;
$palette-panel-10: #8e3303;

$palette-market-05: #f8eaf9;
$palette-market-1: #f0d4f2;
$palette-market-15: #e9bfec;
$palette-market-2: #e1a9e5;
$palette-market-3: #d27ed8;
$palette-market-4: #c353cb;
$palette-market-5: #b428be;
$palette-market-6: #902098;
$palette-market-7: #6c1872;
$palette-market-8: #48104c;
$palette-market-85: #360c39;
$palette-market-9: #240826;
$palette-market-95: #120413;

$color-error: #f44336;
$color-dark-red: #ba0b1e;
$color-valid: #0aae4c;
$color-alert: #e6bf18;
$color-coral: #fb3449;

$color-grey-16: #1e2129;
$color-grey-30: #4f4f4f;
$color-grey-50: #808080;
$color-grey-54: #8c8c8c;
$color-grey-80: #cccccc;
$color-grey-87: #e0e0e0;
$color-grey-92: #ebebeb;
$color-grey-96: #f5f5f5;

$color-light-grey: #dadada;
$color-light-grey-0: #cccccc;
$color-light-grey-1: #d8d8d8;
$color-light-grey-2: #e0e0e0;
$color-light-grey-2-50: rgb($color-grey-87, 50%);
$color-light-grey-3: #f5f5f5;
$color-light-grey-4: #fafafa;
$color-light-grey-5: #f0f0f0;
$color-light-grey-6: #cfcfcf;
$color-light-grey-7: #e5e5e5;
$color-light-grey-8: #767680;
$color-light-grey-9: #f2f2f2;
$color-light-grey-10: #f7f7f7;

$color-medium-grey-1: #8c8c8c;
$color-medium-grey-2: #938f99;
$color-medium-grey-3: #5f5f5f;

$color-dark-grey: #4e4e4e;
$color-dark-grey-1: #1c1b1f;
$color-dark-grey-2: #524343;
$color-dark-grey-3: #49454f;
$color-dark-grey-4: #464a53;
$color-tag-free: rgba(217, 226, 234, 0.8);
$color-footer: #1e2129;

$color-orange: #fb8649;
$color-primary-10: #ff3449;

$color-light-primary: #0b2945;
$color-light-secondary: $palette-market-7;
$color-tag: $color-light-primary;
$color-tag-special: $color-light-secondary;

$color-base-purple: #e8def8;
$color-base-purple-2: #e7e0ec;
$color-base-purple-3: #fffbfe;
$color-purple-47: rgba(180, 40, 190, 0.06);
$color-purple-2-45: rgb($color-base-purple-2, 45%);
$color-purple: $palette-market-5;
$color-architectures-bg: $palette-market-05;
$color-architectures-text: $palette-market-8;
$color-light-purple: #f4eff4;
$color-light-purple-1: #e6e1e5;
$color-dark-blue: #000000;
$color-dark-blue-0: #21005d;
$color-dark-blue-1: #1d192b;
$color-dark-blue-2: #00344f;

$color-light-blue: #cbe6ff;

$color-button-disabled: rgb($color-dark-grey-1, 38%);
$color-bg-button-disabled: rgb(31, 31, 31, 12%);
$color-bg-button-disabled-2: $color-light-grey-6;

$color-purple-50: rgb($palette-market-8, 50%);

//Barberial new colors
$color-black-111111: #111111;

$color-black-87: rgba(0, 0, 0, 0.87); //000000DE
$color-black-64: rgba(0, 0, 0, 0.64); //000000A3
$color-black-48: rgba(0, 0, 0, 0.48); //0000007A
$color-black-32: rgba(0, 0, 0, 0.32); //00000052
$color-black-12: rgba(0, 0, 0, 0.12); //0000001F
$color-black-4: rgba(0, 0, 0, 0.04); //0000000A

$color-blue-dark: #081f36;
$color-blue-medium: #0b2945;
$color-blue-grey: #b6bfc7;
$color-blue-baby: #d9e2ea;
$color-blue-babyintense: #cbe6ff;

$color-blue-87: rgba(11, 41, 69, 0.87); //0B2945DE
$color-blue-64: rgba(11, 41, 69, 0.64); //0B2945A3
$color-blue-48: rgba(11, 41, 69, 0.48); //0B29457A
$color-blue-32: rgba(11, 41, 69, 0.32); //0B294552
$color-blue-12: rgba(11, 41, 69, 0.12); //0B29451F
$color-blue-4: rgba(11, 41, 69, 0.04); //0B29450A

$color-gray-light: #d3d3d3;
$color-gray-lightest: #eeeeee;

$color-green-dark: #0aae4c;
$color-green-medium: #0aae4c;
$color-green-light: #b6e7c9;

$color-grey-medium: #777777;
$color-grey-light: #b4b4b4;
$color-grey-lighter: #d3d3d3;
$color-grey-lightest: #eeeeee;

$color-orange-strong: #fa7339;
$color-orange-medium: #fb8649;
$color-orange-light: #fedbc8;
$color-orange-87: rgba(251, 134, 73, 0.87);
$color-orange-64: rgba(251, 134, 73, 0.64);
$color-orange-48: rgba(251, 134, 73, 0.48);
$color-orange-32: rgba(251, 134, 73, 0.32);
$color-orange-12: rgba(251, 134, 73, 0.12);
$color-orange-4: rgba(251, 134, 73, 0.04);

$color-red-strong: #d32f2f;
$color-red-medium: #f44336;
$color-red-light: #ffcdd2;

$color-red-87: rgba(244, 67, 54, 0.87);
$color-red-64: rgba(244, 67, 54, 0.64);
$color-red-48: rgba(244, 67, 54, 0.48);
$color-red-32: rgba(244, 67, 54, 0.32);
$color-red-12: rgba(244, 67, 54, 0.12);
$color-red-4: rgba(244, 67, 54, 0.04);

$color-solid-basics-white: $color-white;
$color-icons-black: $color-black-111111;
$color-light-grey: #b4b4b4;
$color-button-icon-hover: $color-blue-4;
$color-icons-black-disabled: $color-grey-light;

$color-violet-dark: #a526ae;
$color-violet-medium: #b428be;
$color-violet-hover: #be44c6;
$color-violet-87: rgba(180, 40, 190, 0.87);
$color-violet-32: rgba(180, 40, 190, 0.32);
$color-violet-12: rgba(180, 40, 190, 0.12);
$color-violet-10: rgba(180, 40, 190, 0.1);
$color-violet-20: rgba(180, 40, 190, 0.2);
$color-violet-4: rgba(180, 40, 190, 0.04);