@include media-breakpoint('desktop') {
  body,
  .main-layout__content,
  .variable-binding {
    :root {
      scroll-behavior: smooth;
    }

    /*Scroll azul panel*/
    ::-webkit-scrollbar {
      background-color: rgba(0, 0, 0, 0);
      border-radius: 10px;
      height: 10px;
      width: 10px;
    }

    ::-webkit-scrollbar:hover {
      background-color: rgba(11, 41, 69, 0.12);
    }

    ::-webkit-scrollbar-thumb {
      border: 3px solid transparent;
      border-radius: 10px;
      box-shadow: inset 0 0 0 10px rgba(11, 41, 69, 0.12);
    }

    ::-webkit-scrollbar-thumb:hover {
      border: 1px solid transparent;
      box-shadow: inset 0 0 0 10px rgba(11, 41, 69, 0.32);
    }

    ::-webkit-scrollbar-thumb:active {
      box-shadow: inset 0 0 0 10px rgba(11, 41, 69, 0.64);
    }

    /* Scroll morado anterior
    ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
      background-color: rgb(76 14 87 / 50%);
      border-radius: 10px;
      box-sizing: content-box;
    }
    ::-webkit-scrollbar:hover {
      background-color: rgb(108 24 114 / 50%);
    }
    ::-webkit-scrollbar-thumb {
      border: 2px solid transparent;
      box-shadow: inset 0 0 0 10px rgb(108 24 114 / 100%);
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover,
    ::-webkit-scrollbar-thumb:active {
      border: 1px solid transparent;
      box-shadow: inset 0 0 0 10px rgb(76 14 87 / 100%);
      border-radius: 10px;
    }
    */
  }
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}