$family-inter: 'Inter', sans-serif;
$family-symbols-outlined: 'Material Symbols Outlined', sans-serif;

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/inter/inter-v12-400.eot');
  src:
    local(''),
    url('../../fonts/inter/inter-v12-400.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/inter/inter-v12-400.woff2') format('woff2'),
    url('../../fonts/inter/inter-v12-400.woff') format('woff'),
    url('../../fonts/inter/inter-v12-400.ttf') format('truetype'),
    url('../../fonts/inter/inter-v12-400.svg#Inter') format('svg');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('../../fonts/inter/inter-v12-500.eot');
  src:
    local(''),
    url('../../fonts/inter/inter-v12-500.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/inter/inter-v12-500.woff2') format('woff2'),
    url('../../fonts/inter/inter-v12-500.woff') format('woff'),
    url('../../fonts/inter/inter-v12-500.ttf') format('truetype'),
    url('../../fonts/inter/inter-v12-500.svg#Inter') format('svg');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('../../fonts/inter/inter-v12-600.eot');
  src:
    local(''),
    url('../../fonts/inter/inter-v12-600.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/inter/inter-v12-600.woff2') format('woff2'),
    url('../../fonts/inter/inter-v12-600.woff') format('woff'),
    url('../../fonts/inter/inter-v12-600.ttf') format('truetype'),
    url('../../fonts/inter/inter-v12-600.svg#Inter') format('svg');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/inter/inter-v12-700.eot');
  src:
    local(''),
    url('../../fonts/inter/inter-v12-700.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/inter/inter-v12-700.woff2') format('woff2'),
    url('../../fonts/inter/inter-v12-700.woff') format('woff'),
    url('../../fonts/inter/inter-v12-700.ttf') format('truetype'),
    url('../../fonts/inter/inter-v12-700.svg#Inter') format('svg');
}

//Nueva fuente iconos
.material-icons,
.material-icons-outlined,
.material-symbols-outlined {
  direction: ltr;
  display: inline-block;
  font-family: $family-symbols-outlined;
  -webkit-font-feature-settings: 'liga';
  font-size: 24px;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
}