//Putas modales

.cdk-overlay-pane {
  &.background-grey {
    background-color: $color-light-grey-3;
    border-radius: 5px;
    overflow: hidden;
  }

  &.device-modal-picture,
  &.modal-img,
  &.modal-img-cropper,
  &.background-white {
    background-color: $color-white;
    border-radius: 5px;
    overflow: hidden;
  }
}

.cdk-overlay-backdrop {
  &.backdropBackground {
    background-color: $color-black-50;
  }
}

.mat-mdc-dialog-container {
  background-color: inherit !important;

  & .mdc-dialog__container {
    background-color: inherit !important;

    & .mdc-dialog__surface {
      background-color: inherit !important;
    }
  }

  &.mdc-dialog {
    & .mdc-dialog__content {
      padding: 12px 40px;
      @include media-breakpoint('mobile') {
        height: auto;
        padding: 12px 20px;
      }

      &.add-config-and-readme__main {
        //padding: 0;
      }
    }

    & .mdc-dialog__actions {
      background-color: $color-white;
      bottom: 0;
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      padding: 40px;
      position: sticky;
      right: 0;
      width: 100%;
      z-index: 10;

      &.mat-mdc-dialog-actions-align-center {
        justify-content: center;
      }

      &.mat-mdc-dialog-actions-align-end {
        justify-content: flex-end;
      }

      @include media-breakpoint('mobile') {
        padding: 20px 20px 30px;
      }
    }
  }
}

.modal__closeButton {
  display: flex;
  justify-content: flex-end;
  padding: 24px 40px 0;
  @include media-breakpoint('mobile') {
    padding: 20px 20px 0;
  }
}

.modal__title {
  align-items: center;
  color: $color-black;
  display: flex;
  flex-flow: row nowrap;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
  padding: 24px 40px 0;
  @include media-breakpoint('mobile') {
    padding: 20px 20px 0;
  }

  &-icon {
    margin-right: 10px;
  }

  @include media-breakpoint('mobile') {
    font-size: 16px;
    line-height: 20px;
  }
}

.modal__subtitle {
  align-items: center;
  color: $color-black;
  display: flex;
  flex-flow: row nowrap;
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.1px;
  line-height: 24px;
  margin: 0;
  padding: 0 20px;
  text-transform: uppercase;

  &-icon {
    margin-right: 10px;
  }

  @include media-breakpoint('mobile') {
    font-size: 16px;
    line-height: 20px;
  }
}