@import 'normalize';
@import 'general';
@import 'breakpoints';
@import 'variables';
@import 'fonts';
@import 'colors';
@import 'elevations';
@import 'scrollbars';
@import 'forms';
@import 'buttons';
@import 'modals';

html {
  &.cdk-global-scrollblock {
    overflow: hidden !important;
    position: relative !important;
  }
}

.marketplace-default {
  color: $color-black;
  font-family: $family-inter;
  font-weight: 400;

  & .title-page {
    color: $color-black;
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
    margin: 0;
  }

  & .showing {
    color: $color-grey-30;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    margin: 0;
  }

  & .spinner {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    height: 50vh;
    justify-content: center;
  }

  // sidenav
  & .mat-drawer {
    background-color: $color-white;
    @include elevation-menu;

    @include media-breakpoint('tablet-l') {
      backdrop-filter: initial;
      background-color: $color-white;
    }
    @include media-breakpoint('tablet-p') {
      width: auto;
    }
    @include media-breakpoint('mobile') {
      width: 100%;
    }
  }

  // overlay
  & .mat-drawer-backdrop.mat-drawer-shown {
    background-color: $color-black-03;

    @include media-breakpoint('tablet-l') {
      background-color: $color-black-30;
    }
  }

  //Tooltips
  .mat-mdc-tooltip-hide {
    &.custom-tooltip {
      animation: mat-mdc-tooltip-hide 75ms cubic-bezier(0.4, 0, 1, 1) forwards;
      background-color: $color-transparent;
      color: $color-transparent;
      display: none;

      & .mdc-tooltip__surface {
        background-color: $color-transparent !important;
        color: $color-transparent;
        font-size: 1px;
        font-weight: 500;
        height: 0;
        line-height: 0;
        margin: 0;
        max-width: 0;
        padding: 0;
        white-space: initial;
        width: 0;
      }
    }
  }

  .mat-mdc-tooltip-show {
    &.custom-tooltip {
      animation: mat-mdc-tooltip-show 150ms cubic-bezier(0, 0, 0.2, 1) forwards;
      background-color: $color-transparent;
      color: $color-transparent;
      @include media-breakpoint('tablet-p') {
        animation: mat-mdc-tooltip-show 100ms cubic-bezier(0, 0, 0.2, 1) forwards;
      }

      & .mdc-tooltip__surface {
        background-color: $color-white !important;
        box-shadow:
          0 4px 34.9px 0 rgba(0, 0, 0, 0.02),
          0 2px 2.5px 0 rgba(0, 0, 0, 0.14),
          0 -2px 25.5px 0 rgba(0, 0, 0, 0.08);
        color: $color-black;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 120%;
        max-height: 40vh;
        max-width: 340px !important;
        min-height: 22px;
        min-width: auto !important;
        overflow: hidden;
        padding: 4px !important;
        text-align: left;
        white-space: pre-line;
        width: 100% !important;
        word-break: break-word;
      }
    }
  }

  // Spinner
  & .mat-mdc-progress-spinner {
    &.mat-purple {
      & .mdc-circular-progress {
        &__determinate-circle,
        &__indeterminate-circle-graphic {
          stroke: $color-purple;
        }
      }
    }
  }

  //Tabs
  & .mat-mdc-tab-header {
    & .mat-mdc-tab-header-pagination-before {
    }

    & .mat-mdc-tab-label-container {
      flex-grow: unset;
    }

    & .mat-mdc-tab {
      &:hover {
        background-color: $color-transparent;
      }

      &.mdc-tab {
        height: auto;
        min-width: initial;
        padding: 0;

        & .mdc-tab__content {
          align-items: flex-end;
          margin-right: 8px;
          padding: 0;

          &:hover {
            background-color: $color-transparent;
          }

          & .mdc-tab__text-label {
            //border-bottom: 3px solid transparent;
            border-radius: 40px;
            color: $color-black-111111;
            font-size: 16px;
            font-weight: 600;
            line-height: 120%;
            padding: 16px 20px;

            &:hover {
              background-color: $color-violet-10;
            }
          }
        }

        & .mdc-tab-indicator,
        & .mat-mdc-tab-ripple,
        & .mdc-tab__ripple {
          display: none;
        }

        &.mdc-tab--active {
          & .mdc-tab__text-label {
            // border-bottom: 3px solid $palette-market-7;
            background-color: $color-violet-20;

            &:hover {
              background-color: $color-violet-20;
              cursor: default;
            }
          }
        }
      }
    }
  }

  & .mat-mdc-tab-body-wrapper {
    padding-top: 16px;
  }

  //chips
  .mat-mdc-standard-chip.mdc-evolution-chip--with-primary-graphic
    .mdc-evolution-chip__action--primary {
    cursor: pointer;
    padding: 0 16px;
  }

  .mat-mdc-standard-chip {
    border-radius: 40px;
    --mdc-chip-container-height: 39px;
  }

  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
    background-color: white;
    border: 1px solid #b6bfc7;
  }

  .mat-mdc-standard-chip.mdc-evolution-chip--selected:not(.mdc-evolution-chip--disabled) {
    background-color: #0b2945a3;
    pointer-events: none;
  }

  .mat-mdc-standard-chip.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__graphic {
    display: none;
  }

  .mat-mdc-standard-chip.mdc-evolution-chip--selected:not(.mdc-evolution-chip--disabled)
    .mdc-evolution-chip__text-label {
    color: white;
    font-weight: 600;
  }

  .mat-mdc-standard-chip.mdc-evolution-chip:not(.mdc-evolution-chip--disabled)
    .mdc-evolution-chip__text-label {
    font-size: 14px;
    font-style: normal;
    line-height: 120%;
    text-align: center;
  }

  .mat-mdc-chip.cdk-focused .mat-mdc-chip-focus-overlay {
    background: $color-transparent;
  }

  // Snackbar
  .mat-mdc-snack-bar-container {
    margin-bottom: 36px;
    --mat-snack-bar-button-color: #081f36;
    --mdc-snackbar-container-color: #f5f7f8;
    --mdc-snackbar-supporting-text-color: #111111;
  }

  .mat-mdc-snack-bar-container .mdc-snackbar__label {
    font-family: $family-inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 120%;
    padding: 16px 8px 16px 24px;
  }

  .mat-mdc-snack-bar-container .mdc-snackbar__surface {
    min-width: auto !important;
    padding-right: 12px;
  }
}