.marketplace-default {
  button,
  input[type='email'],
  input[type='tel'],
  input[type='text'],
  input[type='password'],
  input[type='image'],
  input[type='submit'],
  input[type='button'],
  input[type='search'],
  textarea {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    outline: none;
  }

  // Autofill inputs remove background
  input:-webkit-autofill {
    -ms-background-clip: text;
    -webkit-background-clip: text;
    background-clip: text;
  }

  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type='color' i]::-webkit-color-swatch-wrapper {
    border-radius: 0;
    box-sizing: border-box;
    display: flex;
    height: 100%;
    overflow: hidden;
    padding: 0;
    -webkit-user-modify: read-only !important;
    width: 100%;
  }

  input[type='color'] {
    caret-color: transparent !important;
    overflow: hidden;
  }

  input[type='color' i]::-webkit-color-swatch {
    background-color: rgb(0, 0, 0);
    border-color: transparent !important;
    border-image: initial;
    border-style: solid;
    border-width: 0;
    color-scheme: light only;
    flex: 1 1 0;
    min-width: 0;
    -webkit-user-modify: read-only !important;
  }

  input[data-autocompleted] {
    background-color: transparent !important;
  }

  input[type='search']::-webkit-input-placeholder {
    color: $color-grey-light;
  }

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }

  input[type='file'] {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    max-width: 100%;
    outline: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  textarea {
    min-height: 20px;
  }

  //Material overrides

  .mat-mdc-form-field:not(.mat-form-field-appearance-fill) {
    width: 100%;

    & .mat-mdc-text-field-wrapper {
      background-color: $color-white;
      border-radius: 4px;
      color: $color-black;

      & .mat-mdc-form-field-flex {
        align-items: center;
        height: 100%;

        & .mat-mdc-form-field-infix {
          display: inherit;
          min-height: 40px;
          padding: 5px 0;

          & input:-webkit-autofill {
            -ms-background-clip: text;
            -webkit-background-clip: text;
            background-clip: text;
            transform: translateX(5.5%) translateY(0) scale(1.1);
            width: 94%;
          }

          & input::placeholder {
            color: $color-grey-50;
            font-family: $family-inter;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0.5px;
            line-height: 24px;
            transform: translateX(0) translateY(0) scale(1);
          }

          & .mat-mdc-input-element {
            font-family: Inter, sans-serif;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0.5px;
            line-height: 24px;

            &.cdk-text-field-autofilled {
              caret-color: $color-dark-grey;
              color: $color-dark-grey-1;
              flex: 0 0 100%;
              margin: 0;
              max-width: inherit;
              overflow: hidden;
              transform: translateX(0) translateY(0) scale(1);
              vertical-align: middle;
              width: 100%;

              &.ng-pristine {
                transform: translateX(13%) translateY(-5%) scale(1.25);
                width: 80%;
              }

              &.ng-untouched {
                transform: translateX(13%) translateY(-5%) scale(1.25);
                width: 80%;
              }

              &.ng-touched {
                transform: translateX(0.5%) translateY(0) scale(1);
                width: 100%;
              }
            }
          }

          & .mat-mdc-select {
            font-family: Inter, sans-serif;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0.5px;
            line-height: 24px;

            & .mat-mdc-select-trigger {
              & .mat-mdc-select-value {
                & .mat-mdc-select-value-text {
                  display: inline-block;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  vertical-align: middle;
                  width: 100%;

                  & .mat-mdc-select-min-line {
                    display: inline-block;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    vertical-align: middle;
                  }
                }

                & .mat-mdc-select-min-line {
                  display: inline-block;
                  max-width: 100%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  vertical-align: middle;
                }
              }
            }
          }
        }

        & .mat-mdc-form-field-icon-prefix,
        & .mat-mdc-form-field-icon-suffix {
          & > .mat-icon {
            color: $color-grey-50;
            font-size: 24px;
            height: 24px;
            line-height: 24px;
            padding: 5px 16px;
            width: 24px;
          }
        }
      }
    }

    &.mat-focused {
      & .mat-mdc-text-field-wrapper {
        & .mat-mdc-form-field-flex {
          & .mdc-notched-outline {
            .mdc-notched-outline__notch {
              border-top: 2px solid;
              padding: 0;
              width: calc(
                103px * var(--mat-mdc-form-field-floating-label-scale, 0.75) + 9px
              ) !important;
            }

            .mdc-floating-label {
              font-family: Inter, sans-serif;
              font-size: 12px;
              font-weight: 400;
              letter-spacing: 0.5px;
              line-height: 24px;
            }

            .mdc-floating-label--float-above {
              font-family: Inter, sans-serif;
              font-size: 16px;
              font-weight: 400;
              letter-spacing: 0.5px;
              line-height: 24px;
              transform: translateX(2px) translateY(-18px) scale(0.75);
            }
          }

          & .mat-mdc-form-field-infix {
            & .mat-mdc-select {
              & .mat-mdc-select-trigger {
                & .mat-mdc-select-value {
                  & .mat-mdc-select-value-text {
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    & .mat-mdc-select-min-line {
                      display: inline-block;
                      font-family: $family-inter;
                      font-size: 16px;
                      line-height: 24px;
                      max-width: 100%;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .mat-mdc-form-field-subscript-wrapper {
      .mat-mdc-form-field-hint-wrapper {
        .mat-mdc-form-field-hint-spacer {
          /*
            font-family: 'Inter-Regular';
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.5px;
            color: $color-medium-grey;
            margin: 4.5px 0 0;
            display: inline-block;*/
        }
      }

      .mat-mdc-form-field-error {
        color: $color-coral;
        font-family: $family-inter;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.5px;
        line-height: 16px;
      }
    }

    //Select con label(header panel)

    &.select-header {
      .mat-mdc-text-field-wrapper {
        height: 40px;

        .mat-mdc-form-field-flex {
          .mdc-notched-outline {
            & .mdc-notched-outline__notch {
              border-top: 1px solid rgba(0, 0, 0, 0.38);
              width: auto !important;
            }

            & .mat-mdc-floating-label {
              color: rgba(0, 0, 0, 0.87);
              font-family: Inter, sans-serif;
              font-size: 12px;
              font-weight: 400;
              line-height: 14px;
              top: 19px;
            }

            .mdc-floating-label--float-above {
              color: rgba(0, 0, 0, 0.87);
              transform: matrix(1, 0, 0, 1, 0, -14);
            }
          }

          .mat-mdc-form-field-infix {
            .mat-mdc-select {
              & .mat-mdc-select-trigger {
                .mat-mdc-select-value-text {
                  display: inline-block;
                  font-family: Inter, sans-serif;
                  font-size: 12px;
                  font-weight: 400;

                  letter-spacing: 0.5px;
                  line-height: 20px;
                  max-width: 125%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  transform: translateX(0) translateY(14px) scale(1);
                  vertical-align: initial;

                  & .mat-mdc-select-min-line {
                    display: inline-block;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    vertical-align: initial;
                  }
                }
              }
            }
          }

          &:hover {
            .mdc-notched-outline {
              & .mdc-notched-outline__notch {
                border-top: 1px solid rgba(0, 0, 0, 0.87);
              }
            }
          }
        }

        & + .mat-mdc-form-field-subscript-wrapper {
          display: none;

          &:before {
            content: '';
            display: none;
            height: 0;
          }
        }
      }

      &.mat-focused {
        .mat-mdc-text-field-wrapper {
          .mat-mdc-form-field-flex {
            .mdc-notched-outline {
              & .mdc-notched-outline__notch {
                border-top: 2px solid rgba(0, 0, 0, 0.87);
                width: auto !important;
              }

              & .mat-mdc-floating-label {
                color: rgba(0, 0, 0, 0.87);
                font-family: Inter, sans-serif;
                font-size: 12px;
                font-weight: 400;
                line-height: 14px;
                top: 19px;
              }

              .mdc-floating-label--float-above {
                color: rgba(0, 0, 0, 0.87);
                transform: matrix(1, 0, 0, 1, 0, -14);
              }
            }

            .mat-mdc-form-field-infix {
              .mat-mdc-select {
                & .mat-mdc-select-trigger {
                  .mat-mdc-select-value-text {
                    display: inline-block;
                    max-width: 125%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    transform: translateX(-12%) translateY(11px) scale(0.75);

                    & .mat-mdc-select-min-line {
                      display: inline-block;
                      max-width: 100%;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  //Desplegable con las opciones del select
  .mat-mdc-select-panel.mdc-menu-surface {
    background-color: $color-white;
    border: 2px solid $color-black;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
    margin-bottom: 2px !important;
    margin-top: 2px !important;
    max-height: calc(48px * 4) !important;

    //Opciones del resto de selects
    .mat-mdc-option {
      & .mdc-list-item__primary-text {
        display: inline-block;
        font-family: Inter, sans-serif;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.5px;
        line-height: 24px;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;

        white-space: nowrap;
        width: 100%;
      }

      &-active:not(.mat-mdc-option-multiple) {
      }

      &.mdc-list-item--selected {
        background: rgba(73, 69, 79, 0.08);
        color: gray;
        pointer-events: none;

        & .mdc-list-item__primary-text {
          color: inherit;
          font-weight: 500;
        }
      }

      &:hover {
        background: rgba(73, 69, 79, 0.08);
      }

      .mat-mdc-option-pseudo-checkbox {
        &.mat-pseudo-checkbox-minimal {
          height: 16px;
          margin-left: 0;
          width: 16px;

          &:after {
            color: currentColor;
            margin: 0;
            top: 0;
          }
        }
      }
    }

    //Opciones del select con label(header panel)

    &.select-header {
      .mat-mdc-option {
        & .mdc-list-item__primary-text {
          display: inline-block;
          font-family: Inter, sans-serif;
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0.5px;
          line-height: 24px;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &.mdc-list-item--selected {
          background: rgba(73, 69, 79, 0.08);
          color: gray;
          pointer-events: none;

          & .mdc-list-item__primary-text {
            background-color: inherit;
            color: inherit;
            display: inline-block;
            font-family: $family-inter;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0.5px;
            line-height: 24px;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }

    .mat-mdc-option-multiple {
    }
  }

  .mat-mdc-option .mat-mdc-option-ripple {
    z-index: -1 !important;
  }

  //Checkbox
  .mat-mdc-checkbox {
    align-items: inherit;

    &.mat-primary,
    &.mat-purple {
      --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.18);
      --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.18);
      --mdc-checkbox-selected-checkmark-color: #fff;
      --mdc-checkbox-selected-focus-icon-color: #b428be;
      --mdc-checkbox-selected-hover-icon-color: #b428be;
      --mdc-checkbox-selected-icon-color: #b428be;
      --mdc-checkbox-selected-pressed-icon-color: #b428be;
      --mdc-checkbox-unselected-focus-icon-color: #212121;
      --mdc-checkbox-unselected-hover-icon-color: #212121;
      --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
      --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
    }

    &.mat-dark-blue {
      --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.18);
      --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.18);
      --mdc-checkbox-selected-checkmark-color: #fff;
      --mdc-checkbox-selected-focus-icon-color: #00344f;
      --mdc-checkbox-selected-hover-icon-color: #00344f;
      --mdc-checkbox-selected-icon-color: #00344f;
      --mdc-checkbox-selected-pressed-icon-color: #00344f;
      --mdc-checkbox-unselected-focus-icon-color: #212121;
      --mdc-checkbox-unselected-hover-icon-color: #212121;
      --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
      --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
    }

    & .mdc-form-field.mat-internal-form-field {
      align-items: inherit;
    }

    label {
      letter-spacing: 0;
      line-height: 160%;
    }

    &-disabled {
      label {
        color: rgba(0, 0, 0, 0.38);
      }
    }
  }

  //Radio
  .mat-mdc-radio-button {
    margin-right: 20px;

    &.mat-primary,
    &.mat-purple {
      --mat-mdc-radio-checked-ripple-color: #b428be;
      --mat-mdc-radio-ripple-color: #240826;
      --mdc-radio-disabled-selected-icon-color: #240826;
      --mdc-radio-disabled-unselected-icon-color: #240826;
      --mdc-radio-selected-focus-icon-color: #b428be;
      --mdc-radio-selected-hover-icon-color: #b428be;
      --mdc-radio-selected-icon-color: #b428be;
      --mdc-radio-selected-pressed-icon-color: #b428be;
      --mdc-radio-unselected-focus-icon-color: #212121;
      --mdc-radio-unselected-hover-icon-color: #212121;
      --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
      --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
    }

    &.mat-dark-blue {
      --mat-mdc-radio-checked-ripple-color: #00344f;
      --mat-mdc-radio-ripple-color: #000;
      --mdc-radio-disabled-selected-icon-color: #000;
      --mdc-radio-disabled-unselected-icon-color: #000;
      --mdc-radio-selected-focus-icon-color: #00344f;
      --mdc-radio-selected-hover-icon-color: #00344f;
      --mdc-radio-selected-icon-color: #00344f;
      --mdc-radio-selected-pressed-icon-color: #00344f;
      --mdc-radio-unselected-focus-icon-color: #212121;
      --mdc-radio-unselected-hover-icon-color: #212121;
      --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
      --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
    }

    label {
      letter-spacing: 0;
      @include media-breakpoint('tablet-l') {
        letter-spacing: -0.5px;
      }
    }
  }

  //Slider
  .mat-mdc-slider {
    --mdc-slider-label-label-text-font: Inter, sans-serif;
    --mdc-slider-label-label-text-line-height: inherit;
    --mdc-slider-label-label-text-size: inherit;
    --mdc-slider-label-label-text-tracking: normal;
    --mdc-slider-label-label-text-weight: inherit;

    &.mat-primary,
    &.mat-purple {
      --mat-mdc-slider-focus-ripple-color: transparent;
      --mat-mdc-slider-hover-ripple-color: transparent;
      --mat-mdc-slider-ripple-color: transparent;
      --mdc-slider-active-track-color: #48104c;
      --mdc-slider-focus-handle-color: #b428be;
      --mdc-slider-handle-color: #b428be;
      --mdc-slider-hover-handle-color: #b428be;
      --mdc-slider-inactive-track-color: #c353cb;
      --mdc-slider-with-tick-marks-active-container-color: #48104c;
      --mdc-slider-with-tick-marks-inactive-container-color: #c353cb;
    }
  }

  //Slide toggle
  .mat-mdc-slide-toggle {
    .mdc-switch {
      & .mdc-switch__ripple {
        height: 36px !important;
        width: 36px !important;
      }
    }

    &.mat-primary,
    &.mat-purple {
      --mdc-switch-selected-focus-handle-color: #b428be;
      --mdc-switch-selected-focus-state-layer-color: #b428be;
      --mdc-switch-selected-focus-track-color: #e9bfec;
      --mdc-switch-selected-handle-color: #b428be;
      --mdc-switch-selected-hover-handle-color: #b428be;
      --mdc-switch-selected-hover-state-layer-color: #b428be;
      --mdc-switch-selected-hover-track-color: #e9bfec;
      --mdc-switch-selected-pressed-handle-color: #b428be;
      --mdc-switch-selected-pressed-state-layer-color: #b428be;
      --mdc-switch-selected-pressed-track-color: #e9bfec;
      --mdc-switch-selected-track-color: #e9bfec;
    }

    &.mat-dark-blue {
      --mdc-switch-selected-focus-handle-color: #00344f;
      --mdc-switch-selected-focus-state-layer-color: #00344f;
      --mdc-switch-selected-focus-track-color: #cbe6ff;
      --mdc-switch-selected-handle-color: #00344f;
      --mdc-switch-selected-hover-handle-color: #00344f;
      --mdc-switch-selected-hover-state-layer-color: #00344f;
      --mdc-switch-selected-hover-track-color: #cbe6ff;
      --mdc-switch-selected-pressed-handle-color: #00344f;
      --mdc-switch-selected-pressed-state-layer-color: #00344f;
      --mdc-switch-selected-pressed-track-color: #cbe6ff;
      --mdc-switch-selected-track-color: #cbe6ff;
    }
  }

  .mdc-text-field--disabled .mdc-notched-outline__leading,
  .mdc-text-field--disabled .mdc-notched-outline__notch,
  .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: rgba(0, 0, 0, 0.18);
  }

  //Generic styles for all forms
  & [class*='__container-row'] {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  & [class*='__container-col'] {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
  }

  & [class*='__group-container-button'] {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    width: 100%;
  }

  & [class*='__wrapper-button'] {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
  }

  & [class*='__form-field-wrapper'] {
    align-items: baseline;
    display: flex;
    flex-flow: column wrap;
    margin-bottom: 8px;
    width: 100%;
  }

  & [class*='__form-field-label'] {
    color: $color-dark-grey-1;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 24px;
    margin-bottom: 10px;
  }

  & [class*='__form-field-radio-group'] {
    align-items: baseline;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-bottom: 8px;
    width: 100%;
  }

  & [class*='__form-field-checkbox-group'] {
    align-items: baseline;
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 8px;
    width: 100%;
  }

  & [class*='__form-field-slide-toggle'] {
    align-items: baseline;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
  }

  & [class*='__form-field-checkbox-container'] {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 8px;
    width: 100%;
    @include media-breakpoint('mobile') {
      align-items: flex-start;
    }
  }

  & .w-auto {
    width: auto;
  }

  & .w-20 {
    width: 20%;
  }

  & .w-25 {
    width: 25%;
  }

  & .w-30 {
    width: 30%;
  }

  & .w-32 {
    width: 32%;
  }

  & .w-43 {
    width: 43%;
  }

  & .w-50 {
    width: 50%;
  }

  & .w-56 {
    width: 56%;
  }

  & .w-60 {
    width: 60%;
  }

  & .w-75 {
    width: 75%;
  }

  & .w-100 {
    width: 100%;
  }

  // Nuevos estilos para formularios tipo fill
  [class*='__form-field-wrapper'] {
    margin-bottom: 8px;
  }

  .mat-mdc-form-field {
    &.mat-form-field-appearance-fill {
      width: 100%;

      & .mdc-floating-label {
        font-size: 16px;
        font-weight: 400;
        line-height: 120%;
      }

      & .mdc-text-field--filled.mdc-text-field--disabled {
        background-color: $color-gray-lightest;
      }

      & .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background-color: $color-blue-4;
      }

      &:hover {
        & .mat-mdc-form-field-focus-overlay {
          background-color: $color-blue-4;
        }
      }

      &.mat-focused {
        & .mdc-text-field--filled:not(.mdc-text-field--disabled) {
          background-color: $color-blue-4;

          & .mat-mdc-form-field-focus-overlay {
            background-color: $color-blue-4;
          }
        }
      }

      &.input-file {
        background-color: $color-white;

        & .mdc-text-field--filled:not(.mdc-text-field--disabled) {
          &:hover {
            border-width: 1px;
          }

          &.mdc-text-field--focused {
            border-width: 2px;
          }
        }

        & .mdc-text-field--filled:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) {
          border: 1px solid $color-blue-grey;
          //border: 1px solid $color-blue-grey;
          border-radius: 6px;

          &:hover {
            border: 1px solid $color-blue-medium;
          }

          &.mdc-text-field--focused {
            border-color: $color-blue-medium;
            border-width: 2px;

            &:hover {
              border-color: $color-blue-medium;
              border-width: 2px;
            }
          }

          & .mdc-line-ripple::before,
          & .mdc-line-ripple::after {
            border: 0 solid $color-transparent;
          }
        }

        & .mdc-text-field--invalid {
          border: 1px solid $color-red-medium;
          border-radius: 6px;

          & .mdc-line-ripple::before,
          & .mdc-line-ripple::after {
            border: 0 solid $color-transparent;
          }
        }
      }
    }

    .mat-mdc-form-field-icon-suffix {
      padding: 0 4px 0 4px;
    }
  }
}

::ng-deep.mat-mdc-form-field-icon-suffix {
  padding: 0 4px 0 4px !important;
}