//font-size
$font-size-8: 8px;
$font-size-10: 10px;
$font-size-12: 12px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-17: 17px;
$font-size-18: 18px;
$font-size-19: 19px;
$font-size-20: 20px;
$font-size-22: 22px;
$font-size-24: 24px;
$font-size-26: 26px;
$font-size-28: 28px;

//line-height
$line-height-100: 100%;
$line-height-110: 110%;
$line-height-120: 120%;
$line-height-130: 130%;

//padding
$padding-6-12: 6px 12px;
$padding-7-12: 7px 12px;
$padding-6: 6px;
$padding-65: 6.5px;
$padding-8: 8px;
$padding-95: 9.5px;
$padding-10: 10px;
$padding-12: 12px;
$padding-16: 16px;
$padding-8-12: 8px 12px;
$padding-8-16: 8px 16px;
$padding-11-16: 11px 16px;
$padding-12-16: 12px 16px;
$padding-16-24: 16px 24px;

//Variables images
$ratioImage: calc(962 / 563); //1.70870337477798
$ratioIcon: calc(150 / 150);
$widthFeatured: 962px;
//3800
$widthFeatured3800: $widthFeatured;
$widthHomeCategories3800: calc($widthFeatured * 0.45); //(433.8px)
$widthTopSelling3800: calc($widthFeatured * 0.23); //(220.76px)
//1728
$widthFeatured1728: calc($widthFeatured * 0.71); //(683.02px)
$widthHomeCategories1728: calc($widthFeatured1728 * 0.45); //(307.359px)
$widthTopSelling1728: calc($widthFeatured1728 * 0.23); //(157.0946px)
//1280
$widthFeatured1280: calc($widthFeatured * 0.57); //(548.34px)
$widthHomeCategories1280: calc($widthFeatured1280 * 0.45); //(246.753px)
$widthTopSelling1280: calc($widthFeatured1280 * 0.23); //(126.1182px)

/************************************************************
 * nuevas medidas home privada
 ************************************************************/
$heightTopSellingImage1920: 94px;
$widthTopSellingImage1920: calc($heightTopSellingImage1920 * $ratioImage); //160.6181172291px
$heightFeaturedImage1920: 301px;
$widthFeaturedImage1920: calc($heightFeaturedImage1920 * $ratioImage); //514.3197158082px
$heightCategoryImage1920: 184px;
$widthCategoryImage1920: calc($heightCategoryImage1920 * $ratioImage); //314.4014209591px

$heightTopSellingImage1919: 76px;
$widthTopSellingImage1919: calc($heightTopSellingImage1919 * $ratioImage); //129.8614564831px
$heightFeaturedImage1919: 261px;
$widthFeaturedImage1919: calc($heightFeaturedImage1919 * $ratioImage); //445.9715808171px
$heightCategoryImage1919: 148px;
$widthCategoryImage1919: calc($heightCategoryImage1919 * $ratioImage); //252.8880994671px