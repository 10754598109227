@import 'jsoneditor/dist/jsoneditor.min.css';

div.jsoneditor,
div.jsoneditor-menu {
  border: none;
  border-bottom: thin solid #4f4f4f;
  border-left: thin solid #4f4f4f;
  border-right: thin solid #4f4f4f;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

div.jsoneditor-menu {
  background-color: #4f4f4f;

  & > button:not([disabled]) {
    cursor: pointer;
  }
}

.ace_editor.ace-jsoneditor {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  min-height: 49vh;
  //  @include media-breakpoint('xs') {
  //    min-height: 200px;
  //  }
}

.jsoneditor-poweredBy {
  display: none;
}

.jsoneditor-format {
  background-image: url('./assets/icons/icon-format.png') !important;
}

.jsoneditor-compact {
  background-image: url('./assets/icons/icon-compact.png') !important;
}

.jsoneditor-undo {
  background-image: url('./assets/icons/icon_undo.png') !important;
}

.jsoneditor-redo {
  background-image: url('./assets/icons/icon_redo.png') !important;
}

.jsoneditor-repair {
  background-image: url('./assets/icons/ic-tool.png') !important;
}

.jsoneditor-menu > .jsoneditor-modes {
  display: none;
}

.jsoneditor-search {
  right: 12px;
}

.jsoneditor {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.jsoneditor-menu {
  height: 46px;
  padding: 6px;
}

menu > button.jsoneditor-separator {
  margin-left: 15px;
}

.ace_gutter-layer {
  width: 65px !important;
}

.ace_folding-enabled > .ace_gutter-cell {
  padding-right: 30px;
}

.jsoneditor-results {
  margin-top: 5px;
}

.jsoneditor-frame {
  display: flex;
  margin-top: 5px;
  right: 80px;
}

div.jsoneditor-tree {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

div.jsoneditor-tree table.jsoneditor-tree {
  border-collapse: collapse;
  border-spacing: 0;
  overflow: auto;
  width: 100%;
}

.jsoneditor table,
.jsoneditor-modal table {
  border-collapse: collapse;
  overflow: auto;
  width: auto;
}

.ace_text-input {
  opacity: 1 !important;
}

div.jsoneditor-menu {
  background-color: #00344f;
}

div.jsoneditor-outer.has-main-menu-bar {
  margin-top: -46px;
  padding-top: 46px;
}